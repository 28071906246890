
import * as React from 'react'
import { Input, Select, Row, Button, Modal } from "antd-min";
import { GLForm, GLFormProps, FormHelper, FormItemsLayout, FormItemInModalParams, InvitationType, ColTotal, LoginIdType, LoginId, validateLoginId, defaultPhoneCountryCodeList } from 'gl-commonui';
import { GSAdminLocale } from '@app/locales/localeid';
import { lazyInject, TYPES, CONSTS, InvitationTypeText, fmtMsg, InvitationChannel, GLRegistrationLocalizationHelper } from '@app/util/index';
import { IAccountService } from '@app/service/admin/accountservice';
import { IInvitationTemplateService } from '@app/service/invitation-template';
import { IResourceService } from '@app/service/resources';
import { PreviewModal } from '../gl-preview';
import { GLLoginIdInput } from "../register/gl-login-input";
import '@app/components/register/index.less';
import './gl-invitationmodal.less';
import { GLRegistrationLocalization } from '../register/Model';

export interface InviteModalParams {
    title?: string
    width?: string
    height?: string
    emailCaption?: string
    nameCaption?: string
    templateCaption?: string
    referenceId?: string
    invitationtype?: InvitationType | InvitationType[]
    invitationtypeCaption?: string
    references?: string | string[]
}

interface GLInvitationModalProps extends GLFormProps {
    visible?: boolean
    disabled?: boolean
    onSubmit?: (value: any) => void
    onCancel?: (value: any) => void
    modalparams?: InviteModalParams
    enablePhoneRegistration: boolean
    countryPhoneCode?: string;
}

interface GLInvitationModalStates {
    status?: number
    role?: string
    email?: string
    isBack?: boolean
    defaultTemplate?: string
    templateOptions?: JSX.Element[] | null
    isShowPreview?: boolean
}

const enum STATUS {
    ValidateEmail,
    ExistedEmail,
    InviteNew
}

export const NameField = { Caption: GSAdminLocale.UserInvitationName, Name: 'name' };
export const EmailField = { Caption: GSAdminLocale.UserInvitationEmail, Name: 'email' };
export const TemplateField = { Caption: GSAdminLocale.UserInvitationTemplate, Name: 'template' };
export const InvitationTypeField = { Caption: GSAdminLocale.UserInvitationType, Name: 'invitationtype' };

@GLForm.create()
export class GLInvitationModal extends React.Component<GLInvitationModalProps, GLInvitationModalStates> {
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IInvitationTemplateService)
    invitationtemplateservice: IInvitationTemplateService
    @lazyInject(TYPES.IResourceService)
    resourceservice: IResourceService
    form
    renderFormItem
    modalprops
    renderContent
    isHide: boolean
    invitationTarget: LoginId
    variables
    isModalShow = false
    InvitationTypeIndex
    invitationTypeOptions: JSX.Element[] | null
    invitationChannel: InvitationChannel;

    constructor(props) {
        super(props);
        this.state = {
            status: STATUS.ValidateEmail
        };
        this.form = this.props.form;
        this.renderFormItem = FormHelper.renderFormItem;
        this.invitationChannel = InvitationChannel.Email;
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        if (this.props.visible != nextProps.visible && nextProps.visible) {
            this.props.form && this.props.form.resetFields();
            !this.isHide && this.setInvitationTypes();
            this.isModalShow = true;
            this.setState({
                isBack: false,
                status: STATUS.ValidateEmail
            })
        }
    };
    onLoginIdChanged(value: LoginId) {
        this.invitationChannel = value.type == LoginIdType.Email ? InvitationChannel.Email : InvitationChannel.Phone;
    }

    getInvitationTarget() {
        return this.invitationTarget ? (this.invitationTarget.type == LoginIdType.Email ?
            this.invitationTarget.email : `${this.invitationTarget.countryCode}${this.invitationTarget.phone}`) :
            "";
    }

    renderEmailContent() {
        let count = 1 as ColTotal;
        const emailCaption = this.props.modalparams.emailCaption || EmailField.Caption;
        const { countryPhoneCode } = this.props;
        const codeFromList = defaultPhoneCountryCodeList.find(f => f.code === countryPhoneCode);
        const initLoginId = this.state.isBack && this.invitationTarget ?
            {
                country: countryPhoneCode && countryPhoneCode.length ? codeFromList ? codeFromList.counrtryAbbr : "" : "US",
                countryCode: countryPhoneCode && countryPhoneCode.length ? countryPhoneCode : "+1",
                ...this.invitationTarget
            } :
            {
                type: LoginIdType.Email,
                country: countryPhoneCode && countryPhoneCode.length ? codeFromList ? codeFromList.counrtryAbbr : "" : "US",
                countryCode: countryPhoneCode && countryPhoneCode.length ? countryPhoneCode : "+1",
                phone: "",
                email: ""
            }
        const registrationLocalization: GLRegistrationLocalization = GLRegistrationLocalizationHelper.getGLRegistrationLocalization(this.props.enablePhoneRegistration);
        const userEmailParams: FormItemInModalParams = {
            form: this.form,
            localeId: emailCaption,
            fieldName: EmailField.Name,
            formControl:
                <GLLoginIdInput
                    onChange={this.onLoginIdChanged.bind(this)}
                    disabled={false}
                    placeholder={fmtMsg({ id: this.props.enablePhoneRegistration ? GSAdminLocale.RegistrationFormEmailPhonePlaceHolder : GSAdminLocale.RegistrationFormEmailPlaceHolder })}
                    dualMode={this.props.enablePhoneRegistration ? true : false}
                />,
            required: true,
            initialValue: initLoginId,
            // rules: [{validator: (rule: any, value: string, callback: (msg?: string) => void) => {
            //     // inviteEmailValidation(value, callback);
            //     // callback();
            // }}]
            rules: [
                validateLoginId(registrationLocalization)
            ]
        }

        if (!this.props.modalparams.invitationtype) {
            //console.log('Sorry, please pass on an invitation type.');
            return;
        }

        this.isHide = typeof this.props.modalparams.invitationtype == 'number' ? true : false;

        let invitationTypeParams: FormItemInModalParams;
        if (!this.isHide) {
            count = 2 as ColTotal;
            invitationTypeParams = {
                form: this.form,
                localeId: this.props.modalparams.invitationtypeCaption || InvitationTypeField.Caption,
                fieldName: InvitationTypeField.Name,
                formControl: <Select>{...this.invitationTypeOptions}</Select>,
                initialValue: this.state.isBack ? this.InvitationTypeIndex : this.props.modalparams.invitationtype[CONSTS.defaultIndex].toString(),
                required: true,
            }
        }

        return <GLForm form={this.form}  >
            <FormItemsLayout colTotal={count}>
                {this.renderFormItem(userEmailParams)}
                {!this.isHide ? this.renderFormItem(invitationTypeParams) : null}
            </FormItemsLayout>
        </GLForm>
    }

    renderOption(item, index) {
        return <Select.Option key={index} value={index} title={item}>{item}</Select.Option>
    }

    setInvitationTypes() {
        const tempInvitationTypes = this.props.modalparams.invitationtype as number[];
        if (!tempInvitationTypes.length) {
            return;
        }
        const invitationTypes = [...CONSTS.InvitationTypeOrdered.keys()];
        tempInvitationTypes.sort((typeA, typeB) => {
            return invitationTypes.indexOf(typeA) - invitationTypes.indexOf(typeB)
        });

        this.InvitationTypeIndex = tempInvitationTypes[CONSTS.defaultIndex].toString();
        this.invitationTypeOptions = tempInvitationTypes.map(data => {
            return this.renderOption(CONSTS.InvitationType[data.toString()], data.toString());
        });
    }

    renderSuccessContent() {
        let role = CONSTS.InvitationType[this.InvitationTypeIndex];
        let renderString = <><span className='fontcss'>{this.getInvitationTarget()}</span> has been successfully invited as the </>;
        if (role == InvitationTypeText.RegionAdmin) {
            return <>{renderString} Region Admin of <span className='fontcss'>{this.props.modalparams.references}</span>.</>;
        } else if (role == InvitationTypeText.SchoolAdmin) {
            return <>{renderString} School Admin of <span className='fontcss'>{this.props.modalparams.references}</span>.</>;
        } else if (role == InvitationTypeText.Trainer) {
            return <>{renderString} Trainer of <span className='fontcss'>{this.props.modalparams.references}</span>.</>;
        } else if (role == InvitationTypeText.SystemAdmin) {
            return <>{renderString} <span className='fontcss'>System Admin</span>.</>;
        } else if (role == InvitationTypeText.GlobalHead) {
            return <>{renderString} <span className='fontcss'>Global Head</span>.</>;
        } else if (role == InvitationTypeText.TrainingAdmin) {
            return <>{renderString} <span className='fontcss'>Training Admin</span>.</>;
        }
    }

    renderNewContent() {
        const userNameParams: FormItemInModalParams = {
            form: this.form,
            localeId: this.props.modalparams.nameCaption || NameField.Caption,
            fieldName: NameField.Name,
            formControl: <Input />,
            required: true,
        }
        return <>
            {this.invitationTarget.type == LoginIdType.Email ?
                <>
                    <Row>The email address {this.getInvitationTarget()} was not registered in GrapeLEAF account.</Row>
                    <br />
                    <Row>Please enter the name and the invitation template for the person you want to invite.
                        The person will receive an email with an invitation link.</Row>
                    <br />
                </> :
                <>
                    <Row>The phone number {this.getInvitationTarget()} was not registered in GrapeLEAF account.</Row>
                    <br />
                    <Row>Please enter the name and the invitation template for the person you want to invite.
                        The person will receive an sms message with an invitation link.</Row>
                    <br />
                </>
            }
            <GLForm form={this.form}  >
                <FormItemsLayout colTotal={2}>
                    {this.renderFormItem(userNameParams)}
                    {this.renderFormItem(this.form, this.props.modalparams.templateCaption || TemplateField.Caption, TemplateField.Name,
                        <Select>{...this.state.templateOptions}</Select>, this.state.defaultTemplate, true)}
                </FormItemsLayout>
            </GLForm>
        </>
    }

    setInvitationTemplates() {
        let regionId = "", schoolId = "";
        if (this.InvitationTypeIndex == InvitationType.trainer ||
            this.InvitationTypeIndex == InvitationType.schoolAdmin) {
            schoolId = this.props.modalparams.referenceId;
        } else if (this.InvitationTypeIndex == InvitationType.regionAdmin) {
            regionId = this.props.modalparams.referenceId;
        }

        let params = {
            isDescending: true,
            detail: true,
            invitationType: this.InvitationTypeIndex,
            regionId: regionId,
            schoolId: schoolId,
            invitationChannel: this.invitationChannel
        }

        this.invitationtemplateservice.getTemplateCandidates(params).then(data => {
            const sortedData = data.sort(this.sortTemplates());
            let tempTemplateOptions = sortedData.map(template => {
                return this.renderOption(template.name, template.id);
            });
            const filterSchoolId = schoolId == "" ? null : schoolId;
            const filterRegionId = regionId == "" ? null : regionId;
            const defaultTemplate = sortedData.find(template => template.schoolId == filterSchoolId && template.regionId == filterRegionId);
            this.setState({
                defaultTemplate: defaultTemplate ? defaultTemplate.id : (tempTemplateOptions.length ? tempTemplateOptions[0].key : ""),
                templateOptions: tempTemplateOptions
            });
        });
    }

    sortTemplates() {
        return (a, b) => {
            if (a.schoolId && !b.schoolId || (a.regionId && !b.regionId && !b.schoolId)) {
                return -1;
            }
            else if (!a.schoolId && b.schoolId || (!a.schoolId && !a.regionId && b.regionId)) {
                return 1;
            }
            else {
                return a.name.localeCompare(b.name, 'en');
            }
        };
    }

    validateFields() {
        let variables = null;
        this.props.form.validateFieldsAndScroll(null, {}, (err, values) => {
            if (!err) {
                variables = this.props.form.getFieldsValue();
            }
        });
        return variables;
    }

    validateEmail(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.invitationTarget = values[EmailField.Name];
                this.InvitationTypeIndex = values.invitationtype ? values.invitationtype : this.props.modalparams.invitationtype;
                this.accountservice.validateEmail({
                    email: this.getInvitationTarget(),
                    invitationChannel: this.invitationChannel
                }).then(data => {
                    if (data.exist && data.hasConfirmed) {
                        this.bindingUser();
                    } else {
                        this.setInvitationTemplates();
                        this.setState({ status: STATUS.InviteNew });
                    }
                });
            }
        });
    }

    getUserId() {
        return this.accountservice.getUsers({ email: this.getInvitationTarget() });
    }

    bindingUser() {
        const params = {
            email: this.getInvitationTarget(),
            invitationCodeType: this.InvitationTypeIndex,
            referenceId: this.props.modalparams.referenceId,
            invitationChannel: this.invitationChannel
        }
        this.accountservice.bindingUserByEmail(params).then(data => {
            this.setState({ status: STATUS.ExistedEmail });
        }).catch(error => {
            //console.log(error);
        });
    }

    back() {
        this.setState({
            isBack: true,
            status: STATUS.ValidateEmail
        });
    }

    onOk() {
        const variables = this.validateFields();
        if (variables !== null && this.props.onSubmit) {
            this.isModalShow = false;
            variables.invitationtype = this.InvitationTypeIndex;
            variables.email = this.getInvitationTarget();
            variables.countryCode = this.invitationChannel === InvitationChannel.Phone ? this.invitationTarget.countryCode : null,
                variables.invitationChannel = this.invitationChannel;
            variables.callBack = (success) => { if (success) { this.setState({ status: STATUS.ValidateEmail }); } }
            this.props.onSubmit(variables);
        }
    }

    onCancel() {
        this.props.onCancel({ isExistedEmail: this.state.status == STATUS.ExistedEmail });
    }

    preview() {
        if (!this.variables || !Object.keys(this.variables).length) {
            return null;
        }
        const modalparams = {
            type: this.InvitationTypeIndex,
            refid: this.props.modalparams.referenceId,
            tepid: this.variables.template,
            name: this.variables.name,
            email: this.getInvitationTarget(),
            title: this.invitationChannel == InvitationChannel.Phone ? fmtMsg({ id: GSAdminLocale.InvitationPreviewSMSTitle }) : null
        }

        return <PreviewModal modalparams={modalparams} visible={this.state.isShowPreview}
            onCancel={() => { this.variables = null; this.setState({ isShowPreview: false }); }} />
    }

    renderModal() {
        if (this.state.status == STATUS.ValidateEmail) {
            this.modalprops.footer = [
                <Button key="validateemail" type="primary" onClick={(e) => this.validateEmail(e)}>Invite</Button>
            ];
            this.renderContent = this.renderEmailContent();
        } else if (this.state.status == STATUS.ExistedEmail) {
            this.modalprops.footer = [
                <Button key="close" onClick={() => this.onCancel()}>Close</Button>
            ];
            this.renderContent = this.renderSuccessContent();
        } else if (this.state.status == STATUS.InviteNew) {
            this.modalprops.footer = [
                <Button key="invite" disabled={this.isModalShow ? false : this.props.disabled} type='primary' onClick={() => this.onOk()}>Invite</Button>,
                <Button key="preview" onClick={() => {
                    this.variables = this.validateFields();
                    this.setState({ isShowPreview: true });
                }}>Preview</Button>,
                <Button key="back" onClick={() => this.back()}>Cancel</Button>
            ];
            this.renderContent = this.renderNewContent();
        }
    }

    render() {
        this.modalprops = {
            visible: this.props.visible,
            destroyOnClose: true,
            title: this.props.modalparams.title || 'Invite User',
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal gl-invitationmodal",
            width: this.props.modalparams.width || "620px",
            height: this.props.modalparams.height || "400px",
        }

        this.props.visible && this.renderModal();
        return <Modal {...this.modalprops}>
            {this.renderContent}
            {this.state.status == STATUS.InviteNew && this.preview()}
        </Modal>;
    }
}