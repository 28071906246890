import { Action, Container, GLIcon, SecondaryTitle, WijmoGrid } from "@app/components";
import { GLInvitationModal } from "@app/components/admin/gl-invitationmodal";
import { AdminPathConfig as PathConfig } from "@app/config/pathconfig";
import { GSAdminLocale } from "@app/locales/localeid";
import { IInvitationService } from "@app/service/admin/acceptinvitation/index";
import { IRegionService } from "@app/service/admin/regions/interface";
import { RegionAdminProps } from "@app/service/admin/regions/model";
import { IResourceService } from "@app/service/resources/index";
import { IUserService } from "@app/service/users/index";
import { canNotDelete, ContextHelper, fmtMsg, GSAdminAction, InvitationChannel, InvitationHelper, isPhoneRegistrationEnable, lazyInject, sortByDisabled, TYPES } from "@app/util/index";
import { Icon } from "antd-min";
import { GLGlobal, GLUtil, InvitationType, MessageHelper, NotificationType, ResourceOperation, withRouter } from "gl-commonui";
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RegionDeleteModal } from "./components/regionoperation";

const { Grid, Column } = WijmoGrid;

interface RegionInvitePageProps extends RouteComponentProps<any> { }
interface RegionInvitePageStates {
    regionName?: string;
    data: any[];
    loading: boolean;
    recordId?: string;
    region?: string;
    visible?: boolean;
    disabled?: boolean;
    regionDisabled?: boolean;
    resCmd?: ResourceOperation;
    deleteRegionAdminVisible?: boolean;
    isRemoveInvitation?: boolean;
    enablePhoneRegistration: boolean;
    countryPhoneCode?: string;
}

@withRouter
export class RegionInvitePage extends Component<RegionInvitePageProps, RegionInvitePageStates> {
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;
    @lazyInject(TYPES.IInvitationService)
    invitationService: IInvitationService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService;
    regionId = GLUtil.pathParse(PathConfig.RegionInvite).regionId;
    pendingRegionAdmins = [];
    clickedBtns = [];
    isShow = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            enablePhoneRegistration:false
        };
    }

    componentDidMount() {
        this.isShow = true;
        this.getRegionInfo();
        this.concatRegionAdmins();
    }

    getRegionInfo() {
        this.service.getRegionById({ id: this.regionId }).then((data) => {
            this.setState({
                regionName: data.englishName,
                regionDisabled: data.disabled,
                enablePhoneRegistration: data.enablePhoneRegistration,
                countryPhoneCode: data.countryPhoneCode
            });
        });
    }

    concatRegionAdmins() {
        let actions = [this.getPendingRegionAdmins(), this.getResourceIds()];

        Promise.all(actions).then((res) => {
            const [pendings, resourceIds] = res;

            this.pendingRegionAdmins = sortByDisabled(
                pendings.map((item) => {
                    return this.formatdata(item, true);
                })
            );

            if (!resourceIds.length) {
                this.setTableLayout(this.pendingRegionAdmins);
                return;
            }

            this.getRegionAdmins(resourceIds).then((admins) => {
                let adminlist = admins.data.map((item) => {
                    return this.formatdata(item, false);
                });
                const userId = ContextHelper.getUserLoginInfo().profile.sub;
                canNotDelete(adminlist, userId, InvitationType.regionAdmin).then((data) => {
                    this.setTableLayout(data.concat(this.pendingRegionAdmins));
                });
            });
        });
    }

    setTableLoading(isLoading: boolean = true) {
        this.setState({ loading: isLoading });
    }

    setTableLayout(data: any[]) {
        this.setState({ data, loading: false });
    }

    formatdata(item, isPending) {
        return {
            id: item.id,
            name: isPending ? `${item.name} ${fmtMsg({ id: GSAdminLocale.RegionInvitePending })}` : item.name,
            email: item.email,
            phone: item.phone,
            isPending: isPending,
            invitationChannel: item.invitationChannel,
        };
    }

    getPendingRegionAdmins() {
        return this.invitationService.getPendingUsers({
            referenceId: this.regionId,
            invitationCodeTypes: InvitationType.regionAdmin,
        });
    }

    getResourceIds() {
        return this.resourceService.getResourceUserIds({
            resourceId: this.regionId,
            resourceType: InvitationType.regionAdmin,
        });
    }

    getRegionAdmins(ids) {
        return this.userService.getItemsBy({ ids: ids });
    }

    generateInvitationCode(value) {
        const params = {
            invitationType: InvitationType.regionAdmin,
            senderId: GLGlobal.loginInfo().profile.sub,
            referenceId: this.regionId,
            templateId: value.template,
            name: value.name,
            email: value.email,
            phoneCountryCode: value.countryCode,
            invitationChannel: value.invitationChannel
        };
        return this.invitationService.generateInvitationCode(params);
    }

    showInviteModal() {
        const params = {
            referenceId: this.regionId,
            references: this.state.regionName,
            invitationtype: InvitationType.regionAdmin,
            emailCaption: this.state.enablePhoneRegistration ? "registration.form.emailphonetext" : "registration.form.emailtext"
        };
        return (
            <GLInvitationModal
                enablePhoneRegistration={this.state.enablePhoneRegistration}
                countryPhoneCode={this.state.countryPhoneCode}
                visible={this.state.visible}
                disabled={this.state.disabled}
                modalparams={params}
                onSubmit={(value) => {
                    this.setState({ disabled: true });
                    const successMessage = value.invitationChannel == InvitationChannel.Email ?
                        GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSendSuccess }) :
                        GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSendMessageSuccess });
                    const failedMessage = value.invitationChannel == InvitationChannel.Email ?
                        GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveFail }) :
                        GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveMessageFail });
                    this.generateInvitationCode(value)
                        .then((data) => {
                            if (!data.sendEmailSuccess && !data.sendSMSSuccess) {
                                InvitationHelper.sendEmailFail(failedMessage);
                                return;
                            }

                            this.concatRegionAdmins();
                            this.setState({ visible: false });
                            InvitationHelper.sendEmailSuccess(successMessage);
                        })
                        .catch((error) => {
                            this.setState({ disabled: false });
                            this.setTableLoading(false);
                        });
                }}
                onCancel={(value) => {
                    this.setState({ visible: false });
                    value.isExistedEmail && this.concatRegionAdmins();
                }}
            />
        );
    }

    renderOperations(text, record, index, enablePhoneRegistration) {
        const removeToolTip = record.isPending ? fmtMsg({ id: GSAdminLocale.InvitationRemoveTip }) : fmtMsg({ id: GSAdminLocale.RegionInviteRemove });
        let icons = [];
        if (GLGlobal.isActionValid(GSAdminAction.DeleteRegionAdmin)) {
            const icon = record.canNotDel ? (
                <GLIcon type="icon-delete" labelId={GSAdminLocale.RegionInviteRemove} key={1} className="delete disabled"></GLIcon>
            ) : (
                <span
                    title={removeToolTip}
                    key={1}
                    className="icon-delete font-icon action"
                    onClick={(value) => {
                        this.setState({
                            deleteRegionAdminVisible: true,
                            recordId: record.id,
                            isRemoveInvitation: record.isPending,
                        });
                    }}
                />
            );
            icons = [icon];
        }
        let clicked = this.clickedBtns.indexOf(record.id) > -1 ? "clicked" : "";
        record.isPending && GLGlobal.isActionValid(GSAdminAction.InviteRegionAdmin)
            ? icons.unshift(
                record.invitationChannel == InvitationChannel.Email ?
                    <GLIcon
                        type="mail"
                        labelId={GSAdminLocale.InvitationSendEmailTip}
                        className={clicked + " action"}
                        key={0}
                        onClick={(value) => this.resendEmail(record)}
                    /> : enablePhoneRegistration ?
                        <GLIcon
                            type="message"
                            labelId={GSAdminLocale.InvitationSendSMSTip}
                            className={clicked + " action"}
                            key={0}
                            onClick={(value) => this.resendEmail(record)}
                        /> : <></>
            )
            : icons.unshift(<Icon key={0} type="" style={{ width: "18px", margin: "0 5px" }} />);
        return icons;
    }

    resendEmail(record) {
        this.clickedBtns.push(record.id);
        const message = record.invitationChannel == InvitationChannel.Email ?
            fmtMsg({ id: GSAdminLocale.InvitationResendSuccess }) :
            fmtMsg({ id: GSAdminLocale.InvitationResendMessageSuccess });
        this.invitationService
            .sendInvitationMail({ id: record.id })
            .then((data) => {
                if (data.success) {
                    MessageHelper.Message(NotificationType.Success, message);
                }
            })
            .catch((error) => { });
    }

    freshTableLayout() {
        this.setState({
            deleteRegionAdminVisible: false,
            resCmd: ResourceOperation.delete,
            data: this.state.data.filter((item) => item.id !== this.state.recordId),
        });
    }

    deleteRegionAdmin() {
        const modalTitle = this.state.isRemoveInvitation
            ? fmtMsg({ id: GSAdminLocale.InvitationRemoveTip })
            : fmtMsg({ id: GSAdminLocale.RegionInviteRemove });
        const modalMessage = this.state.isRemoveInvitation
            ? fmtMsg({ id: GSAdminLocale.InvitationRemovePrompt }, { name: "admin" })
            : fmtMsg({ id: GSAdminLocale.RegionInviteRemoveList });
        return (
            <RegionDeleteModal
                title={modalTitle}
                visible={this.state.deleteRegionAdminVisible}
                onSubmit={(value) => {
                    if (!this.pendingRegionAdmins.filter((data) => data.id == this.state.recordId).length) {
                        const params = {
                            regionId: this.regionId,
                            userId: this.state.recordId,
                        };
                        this.service.removeRegionAdmin(params).then((data) => {
                            this.freshTableLayout();
                        });
                        return;
                    }
                    // remove pending region admin
                    this.invitationService.deleteInvitationCode({ id: this.state.recordId }).then((data) => {
                        this.pendingRegionAdmins = this.pendingRegionAdmins.filter((pendingData) => pendingData.id != this.state.recordId);
                        this.freshTableLayout();
                    });
                }}
                onCancel={() => {
                    this.setState({ deleteRegionAdminVisible: false });
                }}
                renderContent={() => {
                    return modalMessage;
                }}
            />
        );
    }

    backToList() {
        this.props.history.push({ pathname: PathConfig.Regions });
    }

    render() {
        const canDoPhoneRegistration = isPhoneRegistrationEnable(this.state.enablePhoneRegistration);
        return (
            <Container fullWidth>
                {this.isShow && (
                    <div className="invitation-crud">
                        <div>
                            <SecondaryTitle title={GSAdminLocale.RegionInviteRegionAdmins} />
                            <div className="invitation-crud__content">
                                <Grid
                                    itemsSource={this.state.data}
                                    loading={this.state.loading}
                                    actions={[
                                        !this.state.regionDisabled && (
                                            <Action
                                                key="0"
                                                materialIcon="add"
                                                textLocaleId={GSAdminLocale.RegionInviteAdmins}
                                                onClick={() => this.setState({ visible: true })}
                                            />
                                        ),
                                    ]}
                                >
                                    <Column header={fmtMsg({ id: GSAdminLocale.RegionEditName })} binding={RegionAdminProps.name} />
                                    <Column header={fmtMsg({ id: GSAdminLocale.RegionInviteEmail })} binding={RegionAdminProps.email} />
                                    {canDoPhoneRegistration && < Column header={fmtMsg({ id: GSAdminLocale.RegionInvitePhone })} binding={RegionAdminProps.phone} />}
                                    <Column
                                        align="right"
                                        render={(text, record, index) => {
                                            return (
                                                <span
                                                    className="table-item-actions"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    {this.renderOperations(text, record, index, this.state.enablePhoneRegistration)}
                                                </span>
                                            );
                                        }}
                                    />
                                </Grid>
                            </div>
                        </div>
                        {this.showInviteModal()}
                        {this.deleteRegionAdmin()}
                    </div>
                )}
            </Container>
        );
    }
}
