import * as React from 'react'
import { Component } from 'react'
import { Select, Modal, Form, Input, Button } from "antd-min";
import { lazyInject, TYPES, fmtMsg } from '@app/util/index';
import { IInvitationTemplateService } from '@app/service/invitation-template';
import { GLFormComponentProps, GLForm, FormHelper, FormItemsLayout, InvitationType, FormItemInModalParams, GLGlobal } from 'gl-commonui';
import { RegionModel, RegionAdminProps } from '@app/service/admin/regions/model';
import { GSAdminLocale } from '@app/locales/localeid';

export interface RegionOperationProps {
    model?: RegionModel
    width?: string
    onSubmit?: (value: any) => void
    onCancel?: () => void
}
interface RegionOperationStates {
    visible?: boolean
    defaultTemplate?: string
    templateOptions?: JSX.Element[] | null
}
export abstract class RegionOperationModal<TProps, TStates> extends Component<TProps & RegionOperationProps & GLFormComponentProps, TStates & RegionOperationStates> {
    width?: any
    protected modalTitle?: string;
    protected footer?: JSX.Element[] | null;
    constructor(props, context) {
        super(props, context);
    }

    initState(selfState = { visible: false } as RegionOperationStates & TStates) {
        return { ...selfState as object } as RegionOperationStates & TStates;
    }

    getOption(enumObj, value) {
        return <Select.Option key={value} value={value}>{enumObj}</Select.Option>
    }
    validateFields() {
        let variables = null;
        this.props.form.validateFieldsAndScroll(null, {}, (err, values: RegionModel) => {
            if (!err) {
                this.props.model && (values.id = this.props.model.id)
                variables = this.props.form.getFieldsValue();
            }
        });
        return variables;
    }
    onOk() {
        const variables = this.validateFields();
        if (variables !== null && this.props.onSubmit) {
            this.props.onSubmit(variables);
            this.setState({ visible: false });
            const self = this;
            setTimeout(function(this) {
                self.props.form.resetFields();
            }, 0);
        }
    }
    onCancel() {
        this.setState({ visible: false });
        this.props.onCancel();
        const self = this;
        setTimeout(function(this) {
            self.props.form.resetFields();
        }, 0);
    }
    abstract renderContent()
    render() {
        const props = {
            visible: this.state.visible,
            title: this.modalTitle,
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: this.width || "780px",
            footer: this.footer
        };
        return <Modal {...props}>
            {this.renderContent()}
        </Modal>;
    }
}

@GLForm.create()
export class RegionInviteModal extends RegionOperationModal<any, any> {
    @lazyInject(TYPES.IInvitationTemplateService)
    service: IInvitationTemplateService
    constructor(props, context) {
        super(props, context);
        this.state = this.initState();
        this.modalTitle = fmtMsg({id:GSAdminLocale.RegionInviteAdmins});
        this.footer = [
            <Button key="invite" type="primary" onClick={() => this.onOk()}>{fmtMsg({id:GSAdminLocale.ButtonSubmit})}</Button>,
            <Button key="cancel" onClick={() => this.onCancel()}>{fmtMsg({id:GSAdminLocale.ButtonCancel})}</Button>,
          ];
    }

    componentDidMount() {
        this.setInvitationTemplates();
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        this.props.visible != nextProps.visible && this.setState({ visible: nextProps.visible })
    };

    setInvitationTemplates() {
        let params = { 
            invitationType: InvitationType.regionAdmin,
            detail: false
        }
        this.service.getItemsBy(params).then(data => {
            let tempTemplateOptions = data.data.map(template => {
                return this.getOption(template.name, template.id);
            });
            this.setState({
                defaultTemplate: tempTemplateOptions ? tempTemplateOptions[0].key : "",
                templateOptions: tempTemplateOptions
            });
        });
    }
    
    renderContent() {
        const form = this.props.form;
        const { renderFormItem } = FormHelper;
        const regionNameParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RegionEditName,
            fieldName: RegionAdminProps.name,
            formControl: <Input />,
            required: true,
        }
        const regionadminEmailParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RegionEditEmail,
            fieldName: RegionAdminProps.email,
            formControl: <Input />,
            required: true,
            rules: [FormHelper.ruleForEmail(GSAdminLocale.RegionEditEmail)]
        }
        return (
            <GLForm form={form}  >
                <FormItemsLayout colTotal={2}>
                    {renderFormItem(regionNameParams)}
                    {renderFormItem(regionadminEmailParams)}
                    {renderFormItem(form, GSAdminLocale.RegionEditTemplate, RegionAdminProps.template,
                        <Select>{...this.state.templateOptions}</Select>, this.state.defaultTemplate, true)}
                </FormItemsLayout>
            </GLForm>
        )
    }
}

@GLForm.create()
export class RegionDeleteModal extends RegionOperationModal<any, any> {
    @lazyInject(TYPES.IInvitationTemplateService)
    service: IInvitationTemplateService
    constructor(props, context) {
        super(props, context);
        this.state = this.initState();
        this.modalTitle = fmtMsg({id:GSAdminLocale.UsersEditDelUser});
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        this.props.visible != nextProps.visible && this.setState({ visible: nextProps.visible })
    };
    
    renderContent() {}

    render() {
        const props = {
            visible: this.state.visible,
            title: this.props.title? this.props.title : this.modalTitle,
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: "480px",
        };
        return <Modal {...props}>
            {this.props.renderContent()}
        </Modal>;
    }
}